import React from "react"
// import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalStyle from "../components/globalStyle"
import styled from "styled-components"

const Container = styled.div`
  padding: 20px;
  max-width: 400px;
  margin: 300px auto 0;
`
const NotFoundPage = () => (
  // <Layout>
  <div>
    <GlobalStyle />
    <SEO title="404: Not found" />
    <Container>
      <h1>404: Not Found</h1>
      <p>Yioupssi! Cette adresse n'existe pas...</p>
    </Container>
  </div>
  // </Layout>
)

export default NotFoundPage
